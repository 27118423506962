<template>
  <div class="row mx-0 mb-1">
    <div class="col-12 p-0" v-if="label">
      <div class="form-label" v-html="label"></div>
    </div>
    <!-- <div v-for="option in options" :key="option">
      <span v-if="subDisplayValue">{{option[displayValue][subDisplayValue]}}</span>
      <span v-else>{{option[displayValue]}}</span>
    </div> -->
    <div class="col-12 form-control holder" :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}">
      <select
        v-model="selectedOption"
        class="form-control form-control-inner w-100 medium"
        :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}"
        :disabled="disabled"
      >
        <option :value="option" v-for="option in theOptions" :key="option" :selected="selectedOption.projectID === option.projectID ? 'selected' : ''" class="normal-text-dark">
          {{ displayItem(option)}}
        </option>
        <option value="showMore">Show More</option>
      </select>
      <div class="dropdown_icon" :class="{small: size === 'small', big: size === 'big', gold: color === 'gold', white: color === 'white', error: hasErrors > 0}"><ios-arrow-down-icon /></div>
      <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
        {{ error.$message }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    'ios-arrow-down-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-arrow-down.vue'))
  },
  name: 'SelectChildProject',
  props: ['currency', 'placeholder', 'options', 'displayValue', 'subDisplayValue', 'modelValue', 'errors', 'hasErrors', 'size', 'color', 'disabled', 'label'],
  emits: ['update:modelValue'],
  mounted () {
    this.selectFirst()
  },
  data () {
    return {
      selectedOption: {}
    }
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.selectFirst()
      }
    },
    selectedOption () {
      if (this.selectedOption && this.selectedOption.projectID) {
        this.selectProject()
      }
    },
    value () {
      console.log('HEHEHWE this.value', this.value)
      if (this.value && (this.selectedOption && this.value.project.adminProjectId !== this.selectedOption.projectID)) {
        this.selectProjectFromValue()
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects'
    ]),
    value: {
      get () {
        console.log('GETTT')
        // if (this.modelValue) {
        //   this.selectProjectFromValue()
        // }
        return this.modelValue
      },
      set (value) {
        if (value && value !== 'showMore') {
          this.$emit('update:modelValue', value)
        } else if (value === 'showMore') {
          this.showMore()
        }
      }
    },
    theOptions () {
      const arr = []
      if (this.options && this.options.length > 0) {
        this.options.forEach(element => {
          const ret = this.adminProjects.filter(project => {
            if (project.projectID !== element.project.adminProjectId) {
              return false
            }
            return true
          })
          const obj = { ...ret[0] }
          obj.name = element.name
          arr.push(obj)
        })
      }
      return arr
    }
  },
  methods: {
    selectFirst () {
      console.log('selectFirst')
      if (this.theOptions && this.theOptions.length > 0) {
        this.selectedOption = this.theOptions[0]
      }
    },
    selectProject () {
      if (this.visiblePosters && this.visiblePosters.length > 0) {
        const ret = this.visiblePosters.filter(project => {
          if (project.project.adminProjectId !== this.selectedOption.projectID) {
            return false
          }
          return true
        })
        console.log('ret[0]', ret[0])
        this.value = ret[0]
      }
    },
    selectProjectFromValue () {
      console.log('this.selectProjectFromValue.projectID', this.value)
      if (this.value) {
        const ret = this.theOptions.filter(project => {
          if (project.projectID !== this.value.project.adminProjectId) {
            return false
          }
          return true
        })
        console.log('ret[0]', ret[0])
        this.selectedOption = ret[0]
      }
    },
    showMore () {
      this.$router.push('/donate')
    },
    displayItem (val) {
      let ret = val.name
      if (val.fixed || val.suggested) {
        ret = `${val.name} (${this.currency}${val.cost} ${val.costType.description})`
      }
      return ret
    }
  }
}
</script>

<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }
  .form-control:disabled {
    opacity: 0.5;
    background-color: inherit;
  }
  .form-control:focus {
    color: var(--green-color-light);
    background-color: transparent;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
  .form-control-inner {
    background-color: transparent;
    position: relative;
    color: var(--green-color-light);
    border-radius: 25px;
    border: none;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
    z-index: 2;
  }
  .holder {
    position: relative;
  }
  .dropdown_icon {
    position: absolute;
    color: var(--gold-color);
    top: 0.7rem;
    right: 1rem;
    z-index: 1;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 25px;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0;
    font-weight: 500;
    font-family: "quicksand_medium";
    text-align: left;
  }
  .form-control-inner {
    background-color: transparent;
    position: relative;
    border-radius: 1rem;
    border: none;
    font-size: 1rem;
    height: 2.5rem;
    padding: 0 1rem;
  }
  .form-control.small {
    border: 1px solid var(--green-color-dark);
    font-size: 0.8rem;
    height: calc(2rem + 2px);
    border-radius: 2rem;
  }
  .form-control-inner.small {
    padding: 0 0.5rem;
    height: 2rem;
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  .uppercase {
    text-transform: capitalize;
  }
  .abs {
    position: absolute;
    top: 0;
    left: 0.75rem;
    width: 100%;
  }
</style>
